body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error-message {
  color: red;
  font-size: 14px;
}

.jvtypeicon {
  align-items: center;
  display: flex;
}

.jvtexticon {
  margin-right: 5px;
  color: #ab47bc;
}

.jvcardtitle {
  margin-bottom: 20px !important;
  align-items: center;
  display: flex;
}

.jvcardtitleicon {
  font-size: 28px !important;
  margin-right: 6px;
}

.jvtitleactionicon {
  font-size: 36px !important;
}

.jvtexticonnc {
  font-size: 16px !important;
  margin-right: 10px;
}

.jvtexttitlexl {
  font-size: 18px !important;
  align-items: center;
  display: flex;
}

.jvtexticontitlexl {
  color: #00897B !important;
  font-size: 18px !important;
  margin-right: 5px;
}

.jvstatusboxverdi {
  font-size: 28px;
  padding: 15px 30px 15px 30px;
  border: 1px solid #333;
  border-radius: 18px;
  margin-bottom: 10px;
}

.jvhomeicon {
  color: #ddd;
  align-items: center;
  display: flex;
}

.jvtexttitle {
  /* font-weight: bold !important; */
  font-size: 16px !important;
  align-items: center;
  display: flex;
}

.jvtexticontitle {
  color: #00897B !important;
  font-size: 16px !important;
  margin-right: 5px;
}

.jviconverified {
  color: #90caf9 !important;
  font-size: 20px !important;
}

.jvtablehead {
  font-weight: bold !important;
}

.jvtablerow {
  /* color: #999 !important; */
  border: none !important;
}

.headermenuicon {
  margin-right: 10px;
}

.headerlangicon {
  width: 32px;
  margin: 5px 0x 5px 0px;
}

.headermenulangicon {
  width: 32px;
  margin-right: 10px;
}


.headermenuthemeicon {
  width: 30px;
  margin-right: 10px;
}

.jvbtnicon {
  font-size: 18px !important;
  margin-right: 6px;
}

.CardLink {
  border: 1px solid transparent;
}

.CardLink:hover {
  border: 1px solid #90caf9;
}

.CardLinkA {
  border: 1px solid #1b5e20 !important;
}

.CardLinkA:hover {
  border: 1px solid #90caf9 !important;
}

.CardLinkNA {
  border: 1px solid #c62828 !important;
}

.CardLinkNA:hover {
  border: 1px solid #90caf9 !important;
}

.jvboxicon {
  font-size: 100px !important;
  width: 100% !important;
}

.text-warning {
  color: #ffb74d !important;
}

.tablewiperow {
  color: #FF5733;
  text-decoration: line-through;
}

.cardrettefrist {
  background-color: #FFFDE7 !important;
}

.cardrettefristdark {
  background-color: rgba(255, 202, 40, 0.08) !important;
}

.cardstansevedtak {
  background-color: #FFEBEE !important;
}

.cardstansevedtakdark {
  background-color: rgba(211, 47, 47, 0.08) !important;
}

.cardtaksameter {
  background-color: #F1F8E9 !important;
}

.cardtaksameterdark {
  background-color: rgba(46, 204, 113, 0.08) !important;
}

.color-red {
  color: #f44336 !important;
}

.color-yellow {
  color: #ffa726 !important;
}

.color-blue {
  color: #29b6f6 !important;
}

.color-green {
  color: #66bb6a !important;
}